<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-left">
      <strong>{{ props.item.name || '- - -' }}</strong>
    </td>
    <td class="justify-left">
      <v-icon :color="props.item.type.color">{{ props.item.type.icon }}</v-icon><br>
      <span>{{ props.item.type.name || '- - -' }}</span>
    </td>
    <td class="justify-left">
      <strong v-if="props.item.results_count > 0" style="font-size: 20px;">{{ props.item.results_count }}</strong>
      <span v-else class="process-stage__end">
        <v-icon color="white" small>stop</v-icon>
        Etapa Final
      </span>
    </td>
    <td class="justify-left" style="font-size: 20px;">
      <strong>{{ props.item.count || '- - -' }}</strong>
    </td>
    <td class="justify-left">
      <span v-if="props.item.active" class="process__status process__status--active">
        Ativo
      </span>
      <span v-else class="process__status process__status--inactive">
        Inativo
      </span>
    </td>
    <td class="justify-left" style="padding-top: 4px;">
      <v-btn outline small block :disabled="config.mode == 'select'" @click="listResults()">
        <v-icon>settings</v-icon>
        <span>Resultados</span>
      </v-btn>
    </td>
  </tr>
</template>
<script>
  import processMixin from '@/modules/service-desk/pages/Process/processMixin'
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'ProcessStage',
    methods: {
      listResults: function () {
        this.$WiListDialog({
          wiConfig: 'process-stage-result',
          filter: {
            where: 'process_stage_id,' + this.props.item.id,
            order: 'order,asc'
          },
          wiEditData: {
            type_id: this.props.item.type_id,
            process_stage_id: this.props.item.id,
            process_id: this.props.item.process_id
          }
        })
      }
    },
    props: {
      config: Object,
      props: Object
    },
    mixins: [
      processMixin
    ],
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
  .process-stage__end {
    border-width: 3px;
    border-style: solid;
    border-color: #000;
    padding: 5px 5px;
    color: #eceff1;
    background: rgb(21, 104, 18);
    border-radius: 5px;
  }
  .process__status {
    border-width: 1px;
    border-style: solid;
    padding: 2px 5px;
  }
  .process__status--active {
    color: #eceff1;
    background: rgb(4, 112, 0);
  }
  .process__status--inactive {
    color: grey;
    background: #eceff1;
  }
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>